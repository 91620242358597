import React from "react";
import styles from "./ImageContext.module.css";

const ImageTextComponent = ({ data, isIpad }) => {
  const containerStyle = {
    flexDirection: data.left ? "row" : "row-reverse",
  };

  const imageContainerStyle = {
    borderTopLeftRadius: data.left ? "8px" : "",
    borderBottomLeftRadius: data.left ? "8px" : "",
    borderTopRightRadius: !data.left ? "8px" : "",
    borderBottomRightRadius: !data.left ? "8px" : "",
    overflow: "hidden",
  };

  const textContainerStyle = {
    borderTopLeftRadius: !data.left ? "8px" : "",
    borderBottomLeftRadius: !data.left ? "8px" : "",
    borderTopRightRadius: data.left ? "8px" : "",
    borderBottomRightRadius: data.left ? "8px" : "",
    overflow: "hidden",
  };

  return (
    <div style={containerStyle} className={styles.imageTextComponentMain}>
      <div className={styles.leftMain} style={imageContainerStyle}>
        <img src={isIpad ? data.iPadImage : data.image} alt="" />
      </div>
      <div className={styles.rightMain} style={textContainerStyle}>
        <div className={styles.content}>
          <div className={styles.text1}>{data.text1}</div>
          <div className={styles.text2}>{data.text2}</div>
          <div className={styles.redirect}>
            <a href={data.button.redirect}>
              <span>{data.button.text}</span>
              <span>
                <img src={data.button.image} alt="" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageTextComponent;
