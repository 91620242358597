import React from "react";
import HubspotForm from "react-hubspot-form";
import styles from "./contactForm.module.css";
export const ContactForm = () => {
  return (
    <div className={styles.hubspot}>
      <HubspotForm
        portalId="23353668"
        formId="8f2dae19-4015-4522-9009-bab4b0724782"
        onSubmit={() => console.log("Submit!")}
        onReady={(form) => console.log("Form ready!")}
        loading={<div>Loading...</div>}
        cssClass={styles.hubspot}
      />
    </div>
  );
};

export const ContactFormNew = () => {
  return (
    <div className={styles.hubspot}>
      <HubspotForm
        portalId="23353668"
        formId="8f2dae19-4015-4522-9009-bab4b0724782"
        onSubmit={() => console.log("Submit!")}
        onReady={(form) => console.log("Form ready!")}
        loading={<div>Loading...</div>}
        cssClass={styles.hubspot}
      />
    </div>
  );
};
