import aboutImage1 from "../Assessts/NewAbout/first_1.webp";
import aboutImage1Ipad from "../Assessts/NewAbout/first_1_iPad.webp";
import aboutImage2 from "../Assessts/NewAbout/one.svg";
import aboutImage3 from "../Assessts/NewAbout/two.svg";
import aboutImage4 from "../Assessts/NewAbout/three.svg";
import aboutImage5 from "../Assessts/NewAbout/four.svg";
import aboutImage6 from "../Assessts/NewAbout/five.svg";
import aboutImage7 from "../Assessts/NewAbout/six.svg";

export const NewAboutData = {
  title: "About Us",
  image: aboutImage1,
  iPadImage: aboutImage1Ipad,
  desc: [
    "Wissda is a consulting company specializing in Transformation and Technology solutions & services. We specialize in three key pillars: Risk & Compliance Transformation, Professional Services, and ESG. With a global reach spanning across the USA, Europe, and India, our primary focus revolves around aiding businesses in their transformation journey through seamless integration of automation and innovation.",
    "Distinguished by our commitment to excellence, Wissda is dedicated to delivering exceptional service to our clients. Our goal is to empower clients to reach their business objectives. We pride ourselves on a tailored approach, ensuring personalized and effective solutions that address the unique needs of each client.",
    "Whether implementing technical and data solutions or managing and mitigating risks through integrated risk management, Wissda stands as a trusted partner in the pursuit of business transformation.",
  ],
};

export const GuidingPrincipalData = {
  title: "Our Guiding Principles",
  data: [
    {
      image: aboutImage2,
      title: "Excellence",
      desc:
        "At the core of our DNA is an unwavering commitment to excellence. strive for nothing less than exceptional outcomes in every endeavor, ensuring our clients not only receive solutions but experience the pinnacle of quality.",
    },
    {
      image: aboutImage3,
      title: "Innovation",
      desc:
        "We celebrate innovation as the driving force behind progress. In our relentless pursuit of excellence, we actively seek fresh and inventive approaches. By embracing innovation, we continually redefine possibilities, ensuring our clients not only keep up with the times but lead the way in their industries.",
    },
    {
      image: aboutImage4,
      title: "Integrity",
      desc:
        "Our foundation is built on the bedrock of integrity. We uphold the highest ethical standards, fostering trust and transparency in all our interactions. This ensures that our clients and partners experience a level of honesty and reliability that forms the backbone of enduring relationships.",
    },
    {
      image: aboutImage5,
      title: "Collaboration",
      desc:
        "We champion the power of collaboration. Recognizing that great achievements are seldom solitary. Through close collaboration, we unlock synergies, combining our expertise with theirs to achieve shared goals and aspirations that go beyond individual accomplishments.",
    },
    {
      image: aboutImage6,
      title: "Transparency",
      desc:
        "Transparency is at the core of everything we do. We prioritize openness, providing clarity in our processes. By fostering open communication, we lay the foundation of trust with our internal and external clients, ensuring they are informed, engaged, and confident in every step of our journey together.",
    },
    {
      image: aboutImage7,
      title: "Accountability",
      desc:
        "Accountability is the backbone of our work philosophy and commitments We take ownership of our actions and commitments, holding ourselves accountable to our clients, partners, and each other. By holding ourselves accountable, we build a culture of trust, reliability, and a relentless pursuit of excellence.",
    },
  ],
};

export const AboutBreadCrubmsData = {
  title1: "Home",
  title2: "About Us",
  title3: "About Us",
};
