import React, { useEffect, useState } from "react";
import styles from "./New_C_RT.module.css";
import { New_CRTData } from "../../../Constants/New_CRT";
import { SeoTags } from "../../../Components/SeoTags/SeoTags";

const seoData = {
  title: "The Key to Effective Governance, Risk, and Compliance | Wissda GRC",
  desc:
    "GRC is the key to effective governance, risk, and compliance. By implementing GRC solutions, you can unify and align your organization's approach to risk management and regulatory compliance. Wissda can help you implement GRC solutions that meet your specific needs.",
  canonical: "services/grc",
};

export const New_CRT = () => {
  const [isIpad, setIsIPad] = useState(false);

  const handleResize = () => {
    if (window.innerWidth > 1200) {
      setIsIPad(false);
    } else {
      setIsIPad(true);
    }
  };

  useEffect(() => {
    // Attach the event listener
    window.addEventListener("resize", handleResize);
    handleResize();
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const imageContainerStyle = (isLeft) => {
    return {
      borderTopLeftRadius: isLeft || isIpad ? "8px" : "",
      borderBottomLeftRadius: !isIpad ? isLeft && "8px" : "",
      borderTopRightRadius: !isLeft || isIpad ? "8px" : "",
      borderBottomRightRadius: !isIpad ? !isLeft && "8px" : "",
      overflow: "hidden",
    };
  };

  return (
    <>
      <SeoTags seoData={seoData} />
      <div className={styles.crtHome}>
        {New_CRTData.data.map((item, index) => (
          <div key={index} className={styles.eachItem}>
            <div className={styles.eachTitle}>{item.title}</div>
            <div
              className={styles.eachObj}
              style={{
                flexDirection: isIpad ? "column" : !item.left && "row-reverse",
              }}
            >
              <div
                className={styles.objImage}
                style={imageContainerStyle(item.left)}
              >
                <img src={isIpad ? item.imageIpad : item.image} alt="" />
              </div>
              <div className={styles.objDesc}>
                {item.desc.map((eachDec, index) => (
                  <div key={index}>{eachDec}</div>
                ))}
              </div>
            </div>
          </div>
        ))}
        <div className={styles.lastImage}>
          <img src={New_CRTData.lastImage} alt="" />
        </div>
      </div>
    </>
  );
};
