import buttonImage from "../Assessts/NewHome/right-white-arrow.svg";
import whatWeDoImage1 from "../Assessts/NewHome/whatWeDo/1.webp";
import whatWeDoImage4 from "../Assessts/NewHome/whatWeDo/2.webp";
import whatWeDoImage8 from "../Assessts/NewHome/whatWeDo/3.webp";
import whatWeDoButtonImage from "../Assessts/NewHome/right-red-arrow.svg";
import growTogetherImage from "../Assessts/NewHome/growTogether/illust-environment.svg";
import leftArrow from "../Assessts/NewHome/left-arrow.svg";
import rightArrow from "../Assessts/NewHome/right-arrow.svg";
import leftArrowBlack from "../Assessts/NewHome/left-blackArrow.svg";
import rightArrowBlack from "../Assessts/NewHome/right-blackArrow.svg";
import keyFeatureImage1 from "../Assessts/NewHome/keyFeatures/1.svg";
import keyFeatureImage2 from "../Assessts/NewHome/keyFeatures/2.svg";
import keyFeatureImage3 from "../Assessts/NewHome/keyFeatures/3.svg";
import keyFeatureImage4 from "../Assessts/NewHome/keyFeatures/4.svg";
import keyFeatureImage5 from "../Assessts/NewHome/keyFeatures/5.svg";
import keyFeatureImage6 from "../Assessts/NewHome/keyFeatures/6.svg";
import whatWeDoIpad1 from "../Assessts/NewHome/whatWeDo/home1_ipad.webp";
import whatWeDoIpad2 from "../Assessts/NewHome/whatWeDo/home2_ipad.webp";
import whatWeDoIpad3 from "../Assessts/NewHome/whatWeDo/home3_ipad.webp";
import serviceNow from "../Assessts/NewHome/parteners/service_now.png";

export const NewHomeData = {
  leftData: {
    // text1: "Top-notch, Pragmatic, Versatile",
    text1: "",

    data: [
      {
        text2:
          "Transform Your Business Through Seamless Automation & Innovation",
        text3:
          "Unlock potential, optimize and streamline processes, and achieve sustainable growth",
      },
      {
        text2:
          "Manage Risk Comprehensively, Navigate Uncertainty With Precision",
        text3:
          "Empowering business with our comprehensive risk management and professional service to ensure resilient strategies in an ever-evolving landscape",
      },
    ],

    button: {
      text: "Learn More",
      redirect: "#",
      img: buttonImage,
    },

    arrows: {
      leftArrow,
      rightArrow,
      leftArrowBlack,
      rightArrowBlack,
    },
  },
};

export const WhatWeDoData = {
  title: "What We Do",
  subTitle: "Our Services",
  button: {
    text: "View All",
    image: whatWeDoButtonImage,
    redirect: "/services/professional_services",
  },
  data: [
    {
      image: whatWeDoImage1,
      iPadImage: whatWeDoIpad1,
      text1: "Risk and Compliance Transformation",
      text2:
        "Drive excellence and transform risk into an opportunity with proactive & innovative risk and compliance solutions",
      button: {
        text: "Learn More",
        image: whatWeDoButtonImage,
        redirect: "/services/grc",
      },
      left: true,
    },
    {
      image: whatWeDoImage4,
      iPadImage: whatWeDoIpad2,
      text1: "Professional Services",
      text2:
        "Unlock peak performance and excellence with our flexible staffing, managed services, and strategic consulting",
      button: {
        text: "Learn More",
        image: whatWeDoButtonImage,
        redirect: "/services/professional_services",
      },
      left: false,
    },
    {
      image: whatWeDoImage8,
      iPadImage: whatWeDoIpad3,
      text1: "Environmental, Social, and Governance",
      text2:
        "Thrive responsibly in a rapidly evolving world. Build a resilient, responsible business with ESG solutions that deliver impact",
      button: {
        text: "Learn More",
        image: whatWeDoButtonImage,
        redirect: "/services/esg",
      },
      left: true,
    },
  ],
};

export const KeyFeaturesData = {
  title: "Why Wissda",
  desc: "Key features",
  data: [
    {
      image: keyFeatureImage1,
      title: "Innovation at the Core",
      desc:
        "At Wissda, innovation is not just a buzzword; it's our guiding principle. We bring fresh perspectives and cutting-edge solutions to transform challenges into opportunities.",
      background: "#757C87",
    },
    {
      image: keyFeatureImage2,
      title: "Commitment to Excellence",
      desc:
        "Wissda is dedicated to delivering nothing but excellence. Our commitment to the highest standards ensures that our clients receive services that exceed expectations.",
    },
    {
      image: keyFeatureImage3,
      title: "Global Reach, Local Understanding",
      desc:
        "With presence across the USA, Europe, and India, Wissda offers a global perspective with a local touch. We understand the nuances of different markets, providing you with insights that drive success.",
      background: "#757C87",
    },
    {
      image: keyFeatureImage4,
      title: "Data - Driven Approach",
      desc:
        "Our data-driven approach optimizes processes, empowering organizations to make informed choices to achieve sustainable success in an ever-evolving landscape.",
    },
    {
      image: keyFeatureImage5,
      title: "Sustainable Value Creation",
      desc:
        "We believe in ethical and responsible practices. Our solutions not only drive efficiency and growth but also contribute to a positive impact on your stakeholders and the environment.",
      background: "#757C87",
    },
    {
      image: keyFeatureImage6,
      title: "Execution Based on PPT Framework",
      desc:
        "Nurturing industry bonds, excelling through partnerships, insights, and collaborations, advancing growth with meaningful relationships.",
    },
  ],
};

export const GrowTogetherData = {
  title: "Let’s grow together!",
  desc: "Have more questions about us?",
  image: growTogetherImage,
  button: {
    text: "Contact Us",
    redirect: "/contact",
    img: whatWeDoButtonImage,
  },
};

export const Parteners = {
  img1: serviceNow,
  title: "Wissda Partner",
};
