import React, { useEffect } from "react";
import Header from "./Components/Header";
// import Home from "./Components/Home";
import "./App.css";
import { BrowserRouter, Route, Routes, Link, NavLink } from "react-router-dom";
// import About from "./Pages/OldAbout";
// import About from "./Pages/About";
import Portfolio from "./Pages/Portfolio";
import Careers from "./Pages/careers";
// import BlogsRoutes from "./Pages/BlogsPages/BlogsRoutes";
// import Contact from "./Pages/Contact";
import Footer from "./Components/Footer";
import "bootstrap/dist/css/bootstrap.css";
import GoToTop from "./Components/GoToTop";
import RouteAsObj from "./Pages/RouteAsObject";
import TestEmail from "./Pages/TestEmail";
import Error_Page from "./Components/Error_Page";
import ScrolltoTop from "./Components/ScrolltoTop";
import { NewHome } from "./Pages/NewHome/NewHome";
import { NewAbout } from "./Pages/NewAbout/NewAbout";
import { NewContact } from "./Pages/NewContact/NewContact";
// import Scroller from "./Components/Scroller";

function App() {
  useEffect(() => {
    var _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src =
      "https://cdn.matomo.cloud/wissda.matomo.cloud/container_fa1F1PrP.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <BrowserRouter>
      <ScrolltoTop />
      <Header />
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<NewHome />} />
        {/* <Route path="/about" element={<About />} /> */}
        <Route path="/about" element={<NewAbout />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/services/*" element={<RouteAsObj />}></Route>
        <Route path="/careers" element={<Careers />} />
        {/* <Route path="/blogs-and-news/*" element={<BlogsRoutes />} /> */}
        {/* <Route path="/blogs-and-news/*" element={<RedirectExternal />} /> */}
        {/* <Route path="/contact" element={<Contact />} /> */}
        <Route path="/contact" element={<NewContact />} />
        <Route path="/testEmail" element={<TestEmail />} />
        <Route path="*" element={<Error_Page />} />
      </Routes>
      <GoToTop />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
