import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  NewHomeData,
  WhatWeDoData,
  KeyFeaturesData,
  GrowTogetherData,
  Parteners,
} from "../../Constants/newHome";
import styles from "./NewHome.module.css";
import ImageTextComponent from "../../Components/ImageContext/ImageContext";
import { SeoTags } from "../../Components/SeoTags/SeoTags";

const mainData = {
  title: "Wissda",
  desc:
    "Wissda provides transformation and IT services specializing in Risk and Compliance, Cyber Security, and Data Analytics. We offer automation and innovative solutions to manage and mitigate risks.",
  canonical: "",
};

export const NewHome = () => {
  const [isIpad, setIsIPad] = useState(false);

  const handleResize = () => {
    if (window.innerWidth > 1200 || window.innerWidth < 700) {
      setIsIPad(false);
    } else {
      setIsIPad(true);
    }
  };

  useEffect(() => {
    // Attach the event listener
    window.addEventListener("resize", handleResize);
    handleResize();
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <SeoTags seoData={mainData} />
      <div className={styles.laptopScreen}>
        <VideoPlayer isIpad={isIpad} />
        <VideoOverLapContent NewHomeData={NewHomeData} />
      </div>
      <div className={styles.mobileScreen}>
        <SmallScreenBanner NewHomeData={NewHomeData} />
      </div>
      <WhatWeDo WhatWeDoData={WhatWeDoData} isIpad={isIpad} />
      <KeyFeatures KeyFeaturesData={KeyFeaturesData} isIpad={isIpad} />
      <WissdaParteners partenersData={Parteners} />
      <GrowTogether GrowTogetherData={GrowTogetherData} isIpad={isIpad} />
    </>
  );
};

const VideoPlayer = ({ isIpad }) => {
  const videoStyle = {
    width: "100%",
    height: "80vh",
    // objectFit: "fill",
    objectFit: "cover",
    marginTop: "-5px",
  };
  return (
    <video
      className={styles.videoWrapper}
      style={videoStyle}
      playsInline
      controls={false}
      autoPlay
      loop
      muted
    >
      <source
        src={
          isIpad
            ? "https://zeeropublicaccount.blob.core.windows.net/wiss-website/GIF_i_pad.mp4"
            : "https://zeeropublicaccount.blob.core.windows.net/wiss-website/Video_banner.mp4"
        }
        type="video/mp4"
      />
    </video>
  );
};

const VideoOverLapContent = ({ NewHomeData }) => {
  const [isLeftArrowHovered, setIsLeftArrowHovered] = useState(false);
  const [isRightArrowHovered, setIsRightArrowHovered] = useState(false);

  const handleLeftArrowHover = () => {
    setIsLeftArrowHovered(true);
  };

  const handleLeftArrowLeave = () => {
    setIsLeftArrowHovered(false);
  };

  const handleRightArrowHover = () => {
    setIsRightArrowHovered(true);
  };

  const handleRightArrowLeave = () => {
    setIsRightArrowHovered(false);
  };
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    arrows: false, // Hide arrows
    infinite: true,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Set autoplay speed to 0.5 seconds
  };

  const nextItem = () => {
    sliderRef.current.slickNext();
  };

  const prevItem = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className={styles.videoOverLap}>
      <div className={styles.textWrapperMain}>
        {/* <div className={styles.text1}>{NewHomeData.leftData.text1}</div> */}

        <div>
          <Slider ref={sliderRef} {...settings}>
            {NewHomeData.leftData.data.map((item, index) => (
              <div key={index}>
                <p className={styles.text2}>{item.text2}</p>
                <p className={styles.text3}>{item.text3}</p>
              </div>
            ))}
          </Slider>
        </div>
        {/* <div>
          <a
            className={styles.lastButton}
            href={NewHomeData.leftData.button.redirect}
          >
            <span>{NewHomeData.leftData.button.text}</span>
            <span>
              <img
                className={styles.rightArrow}
                src={NewHomeData.leftData.button.img}
                alt=""
              />
            </span>
          </a>
        </div> */}
      </div>
      <div className={styles.arrows}>
        <div
          className={styles.eachArrow}
          onClick={() => prevItem()}
          onMouseEnter={handleLeftArrowHover}
          onMouseLeave={handleLeftArrowLeave}
        >
          <img
            src={
              isLeftArrowHovered
                ? NewHomeData.leftData.arrows.leftArrowBlack
                : NewHomeData.leftData.arrows.leftArrow
            }
            alt=""
          />
        </div>
        <div
          className={styles.eachArrow}
          onClick={() => nextItem()}
          onMouseEnter={handleRightArrowHover}
          onMouseLeave={handleRightArrowLeave}
        >
          <img
            src={
              isRightArrowHovered
                ? NewHomeData.leftData.arrows.rightArrowBlack
                : NewHomeData.leftData.arrows.rightArrow
            }
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const WhatWeDo = ({ WhatWeDoData, isIpad }) => {
  return (
    <div className={styles.whatWeDoMain}>
      <div className={styles.titlesMain}>
        <div className={styles.one}>
          <div className={styles.two}>{WhatWeDoData.title}</div>
          {/* <div className={styles.three}>{WhatWeDoData.subTitle}</div> */}
        </div>
        {/* <div className={styles.four}>
          <a href={WhatWeDoData.button.redirect}>
            <span>{WhatWeDoData.button.text}</span>
            <span>
              <img src={WhatWeDoData.button.image} alt="" />
            </span>
          </a>
        </div> */}
      </div>
      <div>
        {WhatWeDoData.data.map((item, index) => (
          <ImageTextComponent key={index} data={item} isIpad={isIpad} />
        ))}
      </div>
    </div>
  );
};

const KeyFeatures = ({ KeyFeaturesData }) => {
  return (
    <div className={styles.mainKeyFeatures}>
      <div className={styles.featureTitle}>{KeyFeaturesData.title}</div>
      <div className={styles.keyFeaturesWrapper}>
        <div className={styles.eachRow}>
          {KeyFeaturesData.data.map((item, index) => (
            <EachKeyfeature
              key={index}
              KeyFeaturesData={KeyFeaturesData}
              index={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const EachKeyfeature = ({ KeyFeaturesData, index }) => {
  return (
    <div
      key={index}
      className={styles.eachFeature}
      style={{
        background: KeyFeaturesData.data[index].background
          ? `${KeyFeaturesData.data[index].background}`
          : "",
      }}
    >
      <div className={styles.line1}>
        <div>
          <img src={KeyFeaturesData.data[index].image} alt="" />
        </div>
        <div className={styles.eachTitle}>
          {KeyFeaturesData.data[index].title}
        </div>
      </div>
      <div className={styles.eachDesc}>{KeyFeaturesData.data[index].desc}</div>
    </div>
  );
};

const GrowTogether = ({ GrowTogetherData, isIpad }) => {
  const videoStyle = {
    // width: "100%",
    height: "100%",
  };

  return (
    <div className={styles.growTogether}>
      <div className={styles.growTextContainer}>
        <div className={styles.growTitle}>{GrowTogetherData.title}</div>
        <div className={styles.growButton}>
          <a href={GrowTogetherData.button.redirect}>
            <span>{GrowTogetherData.button.text}</span>
            <span>
              <img src={GrowTogetherData.button.img} alt="" />
            </span>
          </a>
        </div>
      </div>

      <div className={styles.growImageLarge}>
        <video
          style={videoStyle}
          className={styles.plantVideo}
          controls={false}
          playsInline
          autoPlay
          loop
          muted
        >
          <source
            src={isIpad ? "./assets/plant_iPad.mp4" : "./assets/plant2.mp4"}
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
};

const SmallScreenBanner = ({ NewHomeData }) => {
  const [isLeftArrowHovered, setIsLeftArrowHovered] = useState(false);
  const [isRightArrowHovered, setIsRightArrowHovered] = useState(false);

  const handleLeftArrowHover = () => {
    setIsLeftArrowHovered(true);
  };

  const handleLeftArrowLeave = () => {
    setIsLeftArrowHovered(false);
  };

  const handleRightArrowHover = () => {
    setIsRightArrowHovered(true);
  };

  const handleRightArrowLeave = () => {
    setIsRightArrowHovered(false);
  };
  const sliderRef = useRef(null);
  const videoRef = useRef(null);

  const settings = {
    dots: false,
    arrows: false, // Hide arrows
    infinite: true,
    speed: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // Set autoplay speed to 0.5 seconds
  };

  const nextItem = () => {
    sliderRef.current.slickNext();
  };

  const prevItem = () => {
    sliderRef.current.slickPrev();
  };
  const videoStyle = {
    width: "100%",
    height: "auto",
    // objectFit: "fill",
    objectFit: "cover",
    // marginTop: "18px",
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    try {
      videoElement.play();
    } catch (err) {}
  }, []);

  return (
    <>
      <div>
        <video
          style={videoStyle}
          playsInline
          controls={false}
          autoPlay
          loop
          muted
          id="home_video"
          ref={videoRef}
        >
          <source src={"./assets/GIF_mobile.mp4"} type="video/mp4" />
        </video>
      </div>
      <div className={styles.videoContent}>
        <div className={styles.textWrapperMainMobile}>
          <div>
            <Slider ref={sliderRef} {...settings}>
              {NewHomeData.leftData.data.map((item, index) => (
                <div key={index}>
                  <p className={styles.text2}>{item.text2}</p>
                  <p className={styles.text3}>{item.text3}</p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className={styles.arrows}>
        <div
          className={styles.eachArrow}
          onClick={() => prevItem()}
          onMouseEnter={handleLeftArrowHover}
          onMouseLeave={handleLeftArrowLeave}
        >
          <img
            src={
              isLeftArrowHovered
                ? NewHomeData.leftData.arrows.leftArrowBlack
                : NewHomeData.leftData.arrows.leftArrow
            }
            alt=""
          />
        </div>
        <div
          className={styles.eachArrow}
          onClick={() => nextItem()}
          onMouseEnter={handleRightArrowHover}
          onMouseLeave={handleRightArrowLeave}
        >
          <img
            src={
              isRightArrowHovered
                ? NewHomeData.leftData.arrows.rightArrowBlack
                : NewHomeData.leftData.arrows.rightArrow
            }
            alt=""
          />
        </div>
      </div>
    </>
  );
};

const WissdaParteners = ({ partenersData }) => {
  return (
    <>
      <div className={styles.parteners}>
        <div className={styles.partenersTitle}>{partenersData.title}</div>
        <div className={styles.partenerImages}>
          <img src={partenersData.img1} alt="" />
        </div>
      </div>
    </>
  );
};
