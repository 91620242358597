import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styles from "./ServicesMenu.module.css";

function ServicesMenu() {
  const [current, setCurrent] = useState("");
  const navigate = useNavigate();
  const params = useParams();

  // console.log(params["*"], "Outside");

  // if (params === "") {
  //   console.log(params, "mansoor");
  // }

  const handleRedirect = (link) => {
    navigate(link);
  };

  useEffect(() => {
    if (params["*"]) setCurrent(params["*"]);
    // console.log("current=>", params["*"]);
  }, [params]);

  return (
    <section id="breadcrumbs" className="breadcrumbs">
      <div className={`container ${styles.mainBreadCrumbs}`}>
        <div
          className={`container d-flex justify-content-between align-items-center p-md-0 ${styles.subBreadCrumbs}`}
        >
          <div className={styles.breadCrumbsMain}>
            <ol>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Services</li>
            </ol>
            <h2>What We Do</h2>
          </div>
          <span className={`d-flex align-items-Right m-1 ${styles.navParent}`}>
            <nav id="navbar" className={`navbar ${styles.navbarItem}`}>
              <ul>
                <li className={styles.eachItem}>
                  <div
                    className={current === "grc" ? `${styles.active}` : ""}
                    onClick={() => handleRedirect("/services/grc")}
                  >
                    Risk and Compliance Transformation{" "}
                  </div>
                </li>
                <li className={styles.eachItem}>
                  <div
                    className={
                      current === "professional_services"
                        ? `${styles.active}`
                        : ""
                    }
                    onClick={() =>
                      handleRedirect("/services/professional_services")
                    }
                  >
                    Professional Services
                  </div>
                </li>
                <li className={styles.eachItem}>
                  <div
                    className={current === "esg" ? `${styles.active}` : ""}
                    onClick={() => handleRedirect("/services/esg")}
                  >
                    Environmental, Social, and Governance
                  </div>
                </li>
              </ul>
            </nav>
          </span>
        </div>
      </div>
    </section>
  );
}

export default ServicesMenu;
