import first from "../Assessts/New_crt/5.webp";
import firstIpad from "../Assessts/New_crt/5_ipad.webp";
import second from "../Assessts/New_crt/6.webp";
import secondIpad from "../Assessts/New_crt/6_ipad.webp";
import three from "../Assessts/New_crt/7.webp";
import threeIpad from "../Assessts/New_crt/7_ipad.webp";
import four from "../Assessts/New_crt/4.webp";

export const New_CRTData = {
  data: [
    {
      image: first,
      imageIpad: firstIpad,
      left: true,
      title: "Compliance Transformation",
      desc: [
        "Stay ahead of regulatory complexities and expectations with our comprehensive compliance solutions. We provide strategic and practical frameworks to help not only meet but exceed compliance requirements.",
        " Today’s compliance functions need a collaborative, shared, and technical-functional approach to fortify their foundation. At Wissda, we seamlessly blend technology and data expertise with in-depth compliance operational knowledge. Our proactive approach ensures that your organization is well-positioned for regulatory changes and the compliance initiatives embrace perpetual evolution and continuous improvement.",
        "Our unwavering dedication to continuous improvement, stay at the forefront of regulatory shifts, industry benchmarks, and emerging risks, ensuring your programs are always ahead of the curve.",
      ],
    },
    {
      image: three,
      imageIpad: threeIpad,
      left: false,
      title: "Governance, Risk, and Compliance(GRC)",
      desc: [
        "Governance, Risk, and Compliance is a framework to structure governance, risk management, and regulatory compliance through the use of technology. It unifies and aligns an organization’s approach to risk management and regulatory compliance.",
        "We help our client to implement GRC solutions to strengthen the risk management approach by utilizing foundational elements, common taxonomy, and referential to develop workflows, reports, dashboards, and monitoring solutions for an integrated and streamlined system to manage regulatory obligations, and operational objectives.",
      ],
    },
    {
      image: second,
      imageIpad: secondIpad,
      left: true,
      title: "Integrated Risk Management",
      desc: [
        "Integrated Risk Management is a holistic approach to manage and mitigate enterprise wide risk events and improve decision making through a streamlined and collaborative process by connecting people, technology, and data.",
        "To completely understand and evaluate current and future risks, organizations need a comprehensive view of risk across all internal functions and external partners & entities.",
        "We help our clients to establish IRM frameworks and implement processes and solutions to identify, assess, respond, report, and monitor enterprise wide risks.",
      ],
    },
  ],
  lastImage: four,
};
