import React from "react";
import "./C_Y.css";
import Cybersecurity from "../Services-pages/services_Banners/cy-banner.webp";
import cydersecuritybanner from "./sub-services/cyber-seurity.webp";
import cloudSecurity from "./sub-services/Cyber_security_section/new images/cloud-security.webp";
import ApplicationSecurity from "./sub-services/Cyber_security_section/new images/app-security.webp";
import VulnerabilityandThreatManagement from "./sub-services/Cyber_security_section/vulnerability-and-threst-management.webp";
import AccessandIdentityManagement from "./sub-services/Cyber_security_section/new images/identity-access-management.webp";
import EndpointSecurity from "./sub-services/Cyber_security_section/endpoint-security.webp";
import ManagedServices from "./sub-services/Cyber_security_section/managed-service.webp";
import "./Cyber_Security.css";
import styles from "./cyber.module.css";
import { SeoTags } from "../../Components/SeoTags/SeoTags";

const seoData = {
  title: "Comprehensive Cyber Security Solutions | Wissda",
  desc:
    "Wissda's Cyber Security services can help you protect your organization from cyberattacks. We offer a wide range of services, including risk assessment, implementing solutions, and monitoring ongoing threats to ensuring data confidentiality, integrity, and availability",
  canonical: "services/cyber_security",
};

function Cyber_Security() {
  return (
    <>
      <SeoTags seoData={seoData} />
      <section id="blog" className="blog">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-12 entries">
              <article className="entry">
                <div className="entry-img">
                  <img
                    style={{ height: "100%", width: "100%" }}
                    src={Cybersecurity}
                    alt
                    className="img-fluid"
                  />
                </div>
                {/* <h2 className="entry-title">
                  <a>CYBER SECURITY </a>
                </h2> */}

                {/* <div className="entry-meta">
              <ul>
                <li className="d-flex align-items-center"><i className="bi bi-person" /> <a href="blog-single.html">John Doe</a></li>
                <li className="d-flex align-items-center"><i className="bi bi-clock" /> <a href="blog-single.html"><time dateTime="2020-01-01">Jan 1, 2020</time></a></li>
                <li className="d-flex align-items-center"><i className="bi bi-chat-dots" /> <a href="blog-single.html">12 Comments</a></li>
              </ul>
              Consulting: 
Our Consulting team provide domain expertise and required technical and business skills to manage and solve complex business problems. We utilize proven problem-solving methodologies and structured approach to identify, define, and manage solutions. Our approach outlines the deliverables and well-defined roadmap with milestones to manage and govern the projects and solutions delivery

            </div> */}
                <div className="card lg-6 mt-lg-5">
                  <div className="row g-0">
                    <div
                      className="col-lg-6"
                      style={{ maxWidth: "540px", padding: "10px" }}
                    >
                      <img
                        src={cydersecuritybanner}
                        alt
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-lg-6">
                      <div className="card-body">
                        <h2 className="entry-title">
                          <p>Cyber Security </p>
                        </h2>
                        <div className="entry-content">
                          <p
                            style={{
                              textAlign: "justify",
                              textJustify: "inter-word",
                            }}
                          >
                            Technology has changed the business landscape and
                            provided the reach and speed that was unimaginable
                            in the recent past, but it also has exposed us to
                            new risks and vulnerabilities. Our cyber security
                            services help organizations to manage technology
                            risk. The combined approach of technical and
                            business processes protects networks, devices, and
                            data from unauthorized access or criminal use and
                            ensures confidentiality, integrity, and availability
                            of information. We help to assess cyber security
                            risks, develop, and implement solutions, and monitor
                            ongoing risks to develop a timely and effective
                            response.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="entry-content">
                  {/* <div className="read-more">
                <a href="blog-single.html">Read More</a>
              </div> */}
                </div>
              </article>
              {/* End blog entry */}
            </div>
            {/* End blog entries list */}
          </div>
        </div>
      </section>
      {/* start here */}
      <BottomImages />
      {/* <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-md-0">
      //         <Card
      //           className="card"
      //           style={{ width: "425px", height: "300px", cursor: "pointer" }}
      //         >
      //           <span style={{ color: "red", backgroundColor: "#ffffff" }}>
      //             <h5 class="card-header">Application Security</h5>
      //           </span>
      //           <img
      //             src="/assets/img/blog/small_cyber_2.jpg"
      //             class="card-img-top"
      //             alt="..."
      //           />
      //           <div class="card-img-overlay"> */}
      {/* <h5 class="card-title">Application Security</h5> */}
      {/* <p class="card-text">
      //               This is a wider card with supporting text below as a natural
      //               lead-in to additional content. This content is a little bit
      //               longer.
      //             </p> */}
      {/* // <p class="card-text">
      // {/* <small class="text-muted">Last updated 3 mins ago</small> */}
      {/* {" "} */}
      {/* </p>
      //           </div>
      //         </Card>
      //       </div>
      //       <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
      //         <Card
      //           className="card"
      //           style={{ width: "425px", height: "300px", cursor: "pointer" }}
      //         >
      //           <span style={{ color: "red", backgroundColor: "#ffffff" }}>
      //             <h5 class="card-header">
      //               Vulnerability and Threat Management
      //             </h5>
      //           </span>
      //           <img
      //             src="/assets/img/blog/small_cyber_3.jpg"
      //             class="card-img-top"
      //             alt="..." */}
      {/* /> */}
      {/* <div class="card-img-overlay"> */}
      {/* <h5 class="card-title">
      //               Vulnerability and Threat Management
      //             </h5> */}
      {/* <p class="card-text">
      //               This is a wider card with supporting text below as a natural
      //               lead-in to additional content. This content is a little bit
      //               longer.
      //             </p> */}
      {/* <p class="card-text"> */}
      {/* <small class="text-muted">Last updated 3 mins ago</small> */}
      {/* </p>
      //           </div>
      //         </Card>
      //       </div>
      //       <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-5 lg-0">
      //         <Card
      //           className="card"
      //           style={{ width: "425px", height: "300px", cursor: "pointer" }}
      //         >
      //           <span style={{ color: "red", backgroundColor: "#ffffff" }}>
      //             <h5 class="card-header">Access and Identity Management</h5>
      //           </span>
      //           <img
      //             src="/assets/img/blog/small_cyber_4.jpg"
      //             class="card-img-top"
      //             alt="..."
      //           />
      //           <div class="card-img-overlay"> */}
      {/* <h5 class="card-title">Access and Identity Management</h5> */}
      {/* <p class="card-text">
      //               This is a wider card with supporting text below as a natural
      //               lead-in to additional content. This content is a little bit
      //               longer.
      //             </p> */}
      {/* <p class="card-text"> */}
      {/* <small class="text-muted">Last updated 3 mins ago</small> */}{" "}
      {/* </p>
      //           </div>
      //         </Card>
      //       </div>
      //       <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-5 md-0">
      //         <Card
      //           className="col-lg-4 col-md-6 d-flex align-items-stretch mt-5 md-0"
      //           style={{ height: "300px", cursor: "pointer" }}
      //         >
      //           <span style={{ color: "red", backgroundColor: "#ffffff" }}>
      //             <h5 class="card-header">Endpoint Security</h5>
      //           </span>
      //           <img
      //             src="/assets/img/blog/small_cyber_5.jpg"
      //             class="card-img-top"
      //             alt="..."
      //           />
      //           <div class="card-img-overlay"> */}
      {/* <h5 class="card-title">Endpoint Security</h5> */}
      {/* <p class="card-text">
      //               This is a wider card with supporting text below as a natural
      //               lead-in to additional content. This content is a little bit
      //               longer.
      //             </p> */}
      {/* <p class="card-text"> */}
      {/* <small class="text-muted">Last updated 3 mins ago</small> */}
      {/* </p>
      //           </div>
      //         </Card>
      //       </div>
      //       <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-5 lg-0">
      //         <Card
      //           className="card"
      //           style={{ width: "425px", height: "300px", cursor: "pointer" }}
      //         >
      //           <span style={{ color: "red", backgroundColor: "#ffffff" }}>
      //             <h5 class="card-header">Managed Services</h5>
      //           </span>
      //           <img
      //             src="/assets/img/blog/small_cyber_6.jpg"
      //             class="card-img-top"
      //             alt="..."
      //           />
      //           <div class="card-img-overlay"> */}
      {/* <h5 class="card-title">Managed Services</h5> */}
      {/* <p class="card-text">
      //             This is a wider card with supporting text below as a natural
      //             lead-in to additional content. This content is a little bit
      //             longer.
      //           </p> */}
      {/* <p class="card-text"> */}
      {/* <small class="text-muted">Last updated 3 mins ago</small> */}
      {/* </p>
      //           </div>
      //         </Card>
      //       </div>
      //     </div>
      //   </div> */}
      {/* </section>  */}
      {/* <section id="services" className="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-4  col-md-4  align-items mt-4 mt-md-0">
              <div className="wrappers justify-content-right">
                <div className="cards-cy justify-content-center"> */}
      {/* <i className="bx bxl-dribbble" /> */}
      {/* <h1>
                    <span class="enclosed">
                      <a style={{ fontSize: "13px" }}>Cloud</a>
                    </span>
                    <br />
                    <span class="enclosed-2">Security</span>
                  </h1>
                </div> */}
      {/* <p>
                  Voluptatum deleniti atque corrupti quos dolores et quas
                  molestias excepturi
                </p> */}
      {/* </div> */}
      {/* </div> */}
      {/* <div className="col-lg-4 col-md-6  align-items mt-4 mt-md-0"> */}
      {/* <div className="icon-boxs">
                <h4>
                  <a href>Application Security </a>
                </h4>
              </div> */}
      {/* <div className="wrappers justify-content-right">
                <div className="cards-cy justify-content-center"> */}
      {/* <i className="bx bxl-dribbble" /> */}
      {/* <h1>
                    <span className="enclosed">
                      <a style={{ fontSize: "13px" }}>Application</a>
                    </span>
                    <br />
                    Security
                  </h1>
                </div> */}
      {/* <p>
                  Voluptatum deleniti atque corrupti quos dolores et quas
                  molestias excepturi
                </p> */}
      {/* </div> */}
      {/* </div> */}
      {/* <div className="col-lg-4 col-md-6 align-items mt-4 mt-lg-0">
              <div className="wrappers justify-content-right">
                <div className="cards-cy justify-content-center "> */}
      {/* <i className="bx bxl-dribbble" /> */}
      {/* <h1>
                    <span class="enclosed">
                      <a style={{ fontSize: "13px" }}>Vulnerability </a>
                    </span>
                    <br />
                    Threat Management
                  </h1>
                </div> */}
      {/* <p>
                  Voluptatum deleniti atque corrupti quos dolores et quas
                  molestias excepturi
                </p>               */}
      {/* </div> */}
      {/* <div className="icon-boxs">
                <h4>
                  <a href>Vulnerability & Threat Management</a>
                </h4>
              </div> */}
      {/* </div> */}
      {/* <div className="col-lg-4 col-md-6  align-items-stretch mt-4 "> */}
      {/* <div className="icon-boxs">
                <h4>
                  <a href>Access & Identity Management</a>
                </h4>
              </div> */}
      {/* <div className="wrappers "> */}
      {/* <div className="cards-cy justify-content-center "> */}
      {/* <i className="bx bxl-dribbble" /> */}
      {/* <h1>
                    <span class="enclosed">
                      <a style={{ fontSize: "13px" }}>Access & Identity </a>
                    </span>
                    <br />
                    Management
                  </h1>
                </div> */}
      {/* <p>
                  Voluptatum deleniti atque corrupti quos dolores et quas
                  molestias excepturi
                </p>               */}
      {/* </div>
            </div> */}
      {/* <div className="col-lg-4 col-md-6  align-items-stretch mt-4"> */}
      {/* <div className="icon-boxs">
                <h4>
                  <a href>Endpoint Security</a>
                  <br />
                </h4>
              </div> */}
      {/* <div className="wrappers">
                <div className="cards-cy justify-content-center"> */}
      {/* <i className="bx bxl-dribbble" /> */}
      {/* 
                  <h1>
                    <span class="enclosed">
                      <a style={{ fontSize: "13px" }}>EndPoint</a>
                    </span>
                    <br></br>
                    Security
                  </h1>
                </div> */}
      {/* <p>
                  Voluptatum deleniti atque corrupti quos dolores et quas
                  molestias excepturi
                </p>               */}
      {/* </div>
            </div> */}
      {/* <div className="col-lg-4 col-md-6  align-items-stretch mt-4"> */}
      {/* <div className="icon-boxs">
                <h4>
                  <a href>Managed Services</a>
                </h4>
              </div> */}
      {/* <div className="wrappers">
                <div className="cards-cy justify-content-center">
                  <h1>
                    <span class="enclosed">
                      <a style={{ fontSize: "13px" }}>Managed</a>{" "}
                    </span>{" "}
                    <br />
                    Services
                  </h1>
                </div>
              </div>
            </div> */}
      {/* </div> */}
      {/* </div> */}
      {/* </section> */}
      {/* <div class="container">
        <div class="card">
          <div class="face face1">
            <div class="content">
              <img src="https://github.com/Jhonierpc/WebDevelopment/blob/master/CSS%20Card%20Hover%20Effects/img/design_128.png?raw=true" />
              <h3>Design</h3>
            </div>
          </div>
          <div class="face face2">
            <div class="content">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                cum cumque minus iste veritatis provident at.
              </p>
              <a href="#">Read More</a>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="face face1">
            <div class="content">
              <img src="https://github.com/Jhonierpc/WebDevelopment/blob/master/CSS%20Card%20Hover%20Effects/img/code_128.png?raw=true" />
              <h3>Code</h3>
            </div>
          </div>
          <div class="face face2">
            <div class="content">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                cum cumque minus iste veritatis provident at.
              </p>
              <a href="#">Read More</a>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="face face1">
            <div class="content">
              <img src="https://github.com/Jhonierpc/WebDevelopment/blob/master/CSS%20Card%20Hover%20Effects/img/launch_128.png?raw=true" />
              <h3>Launch</h3>
            </div>
          </div>
          <div class="face face2">
            <div class="content">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas
                cum cumque minus iste veritatis provident at.
              </p>
              <a href="#">Read More</a>
            </div>
          </div>
        </div>
      </div> */}
      {/* 
Names of Block:
Cloud Security
Application Security 
Vulnerability and Threat Management
Access and Identity Management
Endpoint Security
Managed Services

*/}
    </>
  );
}

const BottomImages = () => {
  let item = [
    "./sub-services/Cyber_security_section/new images/Cloud Security.jpeg",
  ];

  return (
    <section id="services" className="services">
      <div className="container">
        <div className={`row ${styles.containerMain}`}>
          <div>
            <img src={cloudSecurity} />
          </div>
          <div>
            <img src={ApplicationSecurity} />
          </div>
          <div>
            <img src={VulnerabilityandThreatManagement} />
          </div>
          <div>
            <img src={AccessandIdentityManagement} />
          </div>
          <div>
            <img src={EndpointSecurity} />
          </div>
          <div>
            <img src={ManagedServices} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cyber_Security;
