import React, { useEffect, useState } from "react";
import styles from "./newAbout.module.css";
import {
  NewAboutData,
  GuidingPrincipalData,
  AboutBreadCrubmsData,
} from "../../Constants/newAbout";
import { SeoTags } from "../../Components/SeoTags/SeoTags";
import { BreadCrumbs } from "../../Components/BreadCrumbs/Breadcrumbs";

const seoData = {
  title: "About Wissda | Your Trusted Partner in GRC and ESG",
  desc:
    "Discover Wissda, a consulting firm specializing in Risk Transformation, IT Services, and more. Partner with us to innovate and excel in a competitive world.",
  canonical: "about",
};

export const NewAbout = () => {
  const [isIpad, setIsIPad] = useState(false);

  const handleResize = () => {
    if (window.innerWidth > 1200) {
      setIsIPad(false);
    } else {
      setIsIPad(true);
    }
  };

  useEffect(() => {
    // Attach the event listener
    window.addEventListener("resize", handleResize);
    handleResize();
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <SeoTags seoData={seoData} />
      <BreadCrumbs data={AboutBreadCrubmsData} />
      <NewAboutHeader NewAboutData={NewAboutData} isIpad={isIpad} />
      <GuidingPrincipal GuidingPrincipalData={GuidingPrincipalData} />
    </>
  );
};

const NewAboutHeader = ({ NewAboutData, isIpad }) => {
  const AboutStyles = {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: isIpad ? "" : "8px",
    borderTopRightRadius: isIpad ? "8px" : "",
    overflow: "hidden",
  };

  return (
    <div className={styles.newAboutData}>
      {/* <div className={styles.aboutTitle}>{NewAboutData.title}</div> */}
      <div className={styles.aboutData}>
        <div className={styles.aboutImage} style={AboutStyles}>
          <img
            src={isIpad ? NewAboutData.iPadImage : NewAboutData.image}
            alt=""
          />
        </div>
        <div className={styles.aboutDesc}>
          {NewAboutData.desc.map((item, index) => (
            <div key={index} className={styles.eachDesc}>
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const GuidingPrincipal = ({ GuidingPrincipalData }) => {
  return (
    <div className={styles.guidingPrincipal}>
      <div className={styles.guidingPrincipalTitle}>
        {GuidingPrincipalData.title}
      </div>
      <div className={styles.guidingPrincipalItems}>
        {GuidingPrincipalData.data.map((item, index) => (
          <div key={index} className={styles.guidingPrincipalEachItem}>
            <div className={styles.gImage}>
              <img src={item.image} alt="" />
            </div>
            <div className={styles.gTitle}>{item.title}</div>
            <div className={styles.gdesc}> {item.desc}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
