import React from "react";
import { Link } from "react-router-dom";
export const BreadCrumbs = ({ data }) => {
  return (
    <section id="breadcrumbs" className="breadcrumbs">
      <div className="container  breadCrumbsFullScreen">
        <ol>
          <li>
            <Link to="/">{data.title1}</Link>
          </li>
          <li>{data.title2}</li>
        </ol>
        <h1
          style={{
            fontSize: "28px",
            fontWeight: "700",
            color: "#545454",
            marginTop: "0px",
            marginBottom: ".5rem",
            textAlign: "left",
            fontFamily: "'Raleway', sans-serif",
          }}
        >
          {data.title3}
        </h1>
      </div>
    </section>
  );
};
