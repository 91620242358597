import React from "react";
import HubspotForm from "react-hubspot-form";
import styles from "./careerForm.module.css";
export const CareerForm = () => {
  return (
    <div className={styles.hubspot}>
      <HubspotForm
        portalId="23353668"
        formId="e64f5f6e-d0ba-4bf9-8ef5-fde6226abbcd"
        onSubmit={() => console.log("Submit!")}
        onReady={(form) => console.log("Form ready!")}
        loading={<div>Loading...</div>}
        cssClass={styles.hubspotWissda}
      />
    </div>
  );
};
