import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import styles from "./Header.module.css";
// navbar items
const NavbarItems = [
  {
    title: "Our Services",
    type: "dropdown",
    options: [
      // { title: "ESG", link: "services/esg" },
      { title: "Risk and Compliance Transformation", link: "services/grc" },
      {
        title: "Professional Services",
        link: "services/professional_services",
      },
      { title: "Environmental, Social, and Governance", link: "services/esg" },

      // { title: "Cyber Security", link: "services/cyber_security" },
      // { title: "Data and Analytics", link: "services/data_solutions" },
    ],
  },
  {
    title: "About Us",
    link: "/about",
  },

  {
    title: "Careers",
    link: "/careers",
  },
  {
    title: "Blogs & News",
    link: "/blogs-and-news",
  },
  {
    title: "Contact Us",
    link: "/contact",
  },
];

// Professional Services
// · ESG ·
//  Compliance and Risk Transformation
// · Cyber Security · Data and Analytics
function Header() {
  const [mobileNav, setMobileNav] = useState(false);
  const [mobileclass, setMobileclass] = useState(false);

  const Handler = () => {
    setMobileNav(!mobileNav);
    setMobileclass(!mobileclass);
  };

  return (
    <>
      <header
        id="header"
        className={`d-flex align-items-center ${styles.headerWrapper}`}
      >
        <div
          className={`container d-flex align-items-center justify-content-between ${styles.mainHeaderContainer}`}
        >
          <div className={`logo ${styles.logocontainer}`}>
            <NavLink to="/">
              <img
                src="/assets/img/blog/Wissda_Logo_updated.svg"
                alt=""
                class="img-fluid"
                loading="eager"
                title="Wissda"
                height={"auto"}
                width={"100%"}
              />
            </NavLink>
            <div
              className={styles.officialPartner}
              onClick={() =>
                (window.location.href =
                  "https://wissda.com/blogs/wissda-sponsors-uspl-nj-titans/")
              }
            >
              Official sponsor of USPL NJ Titans
            </div>
          </div>

          <nav
            id="navbar"
            className={mobileNav ? "navbar navbar-mobile" : "navbar"}
          >
            <ul>
              {NavbarItems.map((item, index) =>
                item.type === "dropdown" ? (
                  <NavDropDown item={item} />
                ) : item.title === "Blogs & News" ? (
                  <li key={index} className={styles.paddingLeft}>
                    {" "}
                    <a href="https://wissda.com/blogs/">{item.title}</a>
                  </li>
                ) : (
                  <li key={index} className={styles.paddingLeft}>
                    <NavLink to={item.link} onClick={mobileNav ? Handler : ""}>
                      {item.title}
                    </NavLink>
                  </li>
                )
              )}
            </ul>
            <i
              className={
                mobileclass
                  ? "bi mobile-nav-toggle bi-x"
                  : "bi bi-list mobile-nav-toggle"
              }
              onClick={Handler}
            />
          </nav>
        </div>
      </header>
    </>
  );
}

const NavDropDown = ({ item }) => {
  return (
    <div class={styles.dropdown}>
      <button class={`${styles.dropbtn} dropdown-toggle`}>{item.title}</button>
      <div class={styles.dropdownContent}>
        {item.options.map((ele, i) => (
          <a href={ele.link} key={i} className={styles.links}>
            {ele.title}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Header;
