import React from "react";
import { NavLink, Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer id="footer">
      {/* <div className="footer-newsletter">
        <div className="container">
          <div className="row">
            <div className="col-lg-6" style={{ marginTop: "40px" }}>
              <h4>Our Newsletter</h4> */}
      {/* <p>
                Tamen quem nulla quae legam multos aute sint culpa legam noster
                magna
              </p> */}
      {/* </div> */}
      {/* <div className="col-lg-6"> */}
      {/* <form action method="post"> */}
      {/* <form action method="">
                <input type="email" name="email" />
                <input type="submit" defaultValue="Subscribe" />
              </form>
            </div>
          </div>
        </div>
      </div> */}
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 footer-links">
              <h4>About Us</h4>
              <ul>
                <li>
                  {/* <i className="bx bx-chevron-right" />{" "} */}
                  <i class="bi bi-dot"></i>
                  <NavLink to="/">Home</NavLink>
                </li>
                {/* <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <NavLink to="/about">About us</NavLink>
                </li> */}
                {/* <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <NavLink to="/Services">Service</NavLink>
                </li> */}
                <li>
                  {/* <i className="bx bx-chevron-right" />{" "} */}
                  <i class="bi bi-dot"></i>
                  <NavLink to="/careers">Careers</NavLink>
                </li>
                {/* <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <NavLink to="/blog">Blog</NavLink>
                </li> */}
                <li>
                  {/* <i className="bx bx-chevron-right" />{" "} */}
                  <i class="bi bi-dot"></i>
                  <NavLink to="/contact">Contact</NavLink>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  {/* <i className="bx bx-chevron-right" />{" "} */}
                  <i class="bi bi-dot"></i>
                  <Link to="services/grc">
                    Risk and Compliance Transformation
                  </Link>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right" />{" "} */}
                  <i class="bi bi-dot"></i>
                  <Link to="services/professional_services">
                    Professional Services
                  </Link>
                </li>
                <li>
                  {/* <i className="bx bx-chevron-right" />{" "} */}
                  <i class="bi bi-dot"></i>
                  <Link to="services/esg">
                    Environmental, Social, and Governance
                  </Link>
                </li>

                {/* <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <Link to="services/cyber_security">Cyber Security</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right" />{" "}
                  <Link to="services/data_solutions">Data and Analytics</Link>
                </li> */}
                {/* <li><i className="bx bx-chevron-right" /> <Link to="">Graphic Design</Link></li> */}
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 footer-contact">
              <h4>Contact Us</h4>
              <p>
                #101 Hudson Street, Jersey City,
                <br />
                NJ-07302, United States.
                {/* United States <br /> */}
                {/* Phone: +1 6786449967 <br /> */}
                {/* <strong>Email:</strong> info@Wissda.com
                <br /> */}
              </p>
              <p>
                Mayo Center, Ground Floor, No. 12,
                {/* <br />
                India
                <br /> */}
                <br />
                Commissariat Road, Bangalore, India - 560025
                {/* <strong>Phone:</strong> +91 9900129375 */}
                {/* Phone: +91 9900129375 */}
                {/* <br />
                <strong>Email:</strong> info@Wissda.com
                <br /> */}
              </p>

              <div className="social-links mt-3">
                <a
                  href="https://twitter.com/Wissda_Inc"
                  target="blank"
                  className="twitter"
                >
                  <div style={{ display: "none" }}>twitter-wissda</div>{" "}
                  {/* <i className="bx bxl-twitter" /> */}
                  {/* <i class="bi bi-twitter-x"></i>
                   */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-twitter-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                  </svg>
                </a>
                {/* <Link to="#" className="facebook">
                  <i className="bx bxl-facebook" />
                </Link>
                <Link to="#" className="google-plus">
                  <i className="bx bxl-instagram" />
                </Link> */}
                <a
                  href="https://www.linkedin.com/company/wissda/"
                  target="blank"
                  className="linkedin"
                >
                  <div style={{ display: "none" }}>linkedin-wissda</div>{" "}
                  <i className="bx bxl-linkedin" />
                </a>
              </div>
            </div>
            {/* <div className="col-lg-3 col-md-6 footer-info">
              <h3>Follow Us</h3> */}
            {/* <p
                style={{
                  textAlign: "justify",
                  textJustify: "inter-word",
                }}
              >
                Wissda supports some of the Fortune 100 companies in various
                areas. We focus on US and AMEA Market with delivery offices at
                US & India.
              </p> */}
            {/* <div className="social-links mt-3">
                <Link to="#" className="twitter">
                  <i className="bx bxl-twitter" />
                </Link>
                <Link to="#" className="facebook">
                  <i className="bx bxl-facebook" />
                </Link>
                <Link to="#" className="google-plus">
                  <i className="bx bxl-instagram" />
                </Link>
                <Link to="#" className="linkedin">
                  <i className="bx bxl-linkedin" />
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          © Copyright{" "}
          <strong>
            <span>Wissda</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          {/* All the links in the footer should remain intact. */}
          {/* You can delete the links only if you purchased the pro version. */}
          {/* Licensing information: https://bootstrapmade.com/license/ */}
          {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/eterna-free-multipurpose-bootstrap-template/ */}
          Designed by <a href="https://Wissda.com/">Wissda.com</a>
        </div>
      </div>
    </footer>
  );
}
