import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

const mainData = {
  title: "Wissda",
  desc:
    "Wissda provides transformation and IT services specializing in Risk and Compliance, Cyber Security, and Data Analytics. We offer automation and innovative solutions to manage and mitigate risks.",
  canonical: "",
};

export const SeoTags = ({ seoData = mainData }) => {
  const [baseUrl, setBaseUrl] = useState("");

  useEffect(() => {
    const base_url = window.location.origin;
    // console.log("base url==>", `${base_url}/${seoData.canonical}`);
    setBaseUrl(base_url);
  }, []);

  return (
    <Helmet>
      <title>{seoData.title}</title>
      <meta name="description" content={seoData.desc} />
      <meta name="og:title" content={seoData.title} />
      <meta name="og:description" content={seoData.desc} />
      <meta name="og:url" content={`${baseUrl}/${seoData.canonical}`} />
      <link rel="canonical" href={seoData.canonical} />
    </Helmet>
  );
};
